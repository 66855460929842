.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.markdown-container {
  flex: 1 0 auto;
  line-height: 1.6;
  width: 60%;
  min-width: 60%;
  text-align: left;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  color: var(--accent);
}

h1 {
  font-size: 1.75em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.12em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.9em;
}

p {
  margin-bottom: 20px;
}

a {
  color: var(--accent);
  text-decoration: none;
  transition: opacity 0.3s;
}

a:hover {
  opacity: 0.7;
}

s, del {
  text-decoration: line-through;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

ul, ol {
  margin-bottom: 20px;
  padding-left: 30px;
}

li {
  margin-bottom: 10px;
}

li strong {
  color: var(--accent);
}

hr {
  border: none;
  height: 3px;
  background-color: rgba(66, 66, 68, 1);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

:not(blockquote) > blockquote {
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 20px;
  border-left: 4px solid var(--accent);
  color: var(--text);
  background-color: rgba(66, 66, 68, 1);
  font-style: italic;
}

blockquote > blockquote {
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 20px;
  border-left: 4px solid var(--accent);
  color: var(--text);
  background-color: rgba(0, 0, 0, 0.15);
  font-style: italic;
}

pre {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 15px;
  overflow-x: auto;
  border-radius: 5px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-x: auto;
}

:not(pre) > code {
  font-family: 'Ubuntu Mono', 'Courier New', Courier, monospace;
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 3px 5px;
  border-radius: 3px;
}

pre > code {
  font-family: 'Ubuntu Mono', 'Courier New', Courier, monospace;
  font-size: 0.9em;
  padding: 3px 0px;
  border-radius: 3px;
  white-space: inherit;
  overflow-wrap: break-word;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

table, th, td {
  border: 2px solid rgba(132, 132, 136, 1);
}

th, td {
  padding: 0.5rem;
  text-align: left;
}

th {
  background-color: var(--background);
  color: var(--text);
}

img {
  max-width: 100%;
  display: block;
  margin: 20px auto;
}

.banner-image {
  width: 100%;
  height: 15vh;
  object-fit: cover;
  margin: 0.5em 0;
  border-radius: 8px;
}

sup {
  font-family: 'Ubuntu Mono', 'Courier New', Courier, monospace;
  font-size: 0.75em;
  color: var(--text);
}

section.footnotes {
  font-size: 0.9em;
  color: var(--text);
  margin-top: 2rem;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 25px;
  overflow-x: auto;
  border-radius: 5px;
}

.universal-footer {
  flex-shrink: 0;
}

/* For narrow viewports and mobile devices */
@media (max-device-width: 768px) and (orientation: portrait) {
  .markdown-container, .universal-footer {
      width: 90%;
      min-width: 90%;
  }
  .banner-image {
      height: 7.5vh;
  }
}